/* App-wide CSS rules */

html {
    --scrollbarBG: #027c7633;
    --thumbBG: #82d4ce;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overflow: auto;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
}

#root {
    height: 100%;
}

*::-webkit-scrollbar {
    width: 8px;
}
* {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
*::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}
*::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
}
